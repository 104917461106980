.form-check {
    .form-check-input {
        float: none;
        margin-left: inherit;
    }
}

form {
    fieldset {
        margin-top: rem(16px);
        legend {
            font-family: $font-title;
            color: $green-400;
            line-height: 1.1;
            font-size: rem(22px);
            margin-bottom: rem(16px);
            @include media-breakpoint-up(lg) { 
                font-size: rem(24px);
            }
        }

        .participant-infos {
            display: flex;
            .d-flex {
                > div {
                    width: 100%;
                    @include media-breakpoint-up(md) { 
                        display: grid;
                        grid-gap: rem(16px);
                        grid-template-columns: repeat(2, minmax(0, 1fr));
                    
                    }

                }
                span {
                    color: $green-400;
                    margin-right: rem(24px);
                }
            }
        }
    }

    &.was-validated {
        .form-control {
            &:valid {
                border: rem(1px) solid $green-300;
                background-image: none;
                &:focus {
                    border: rem(1px) solid $green-300;
                    box-shadow: none;
                }
                &:hover {
                    border: rem(1px) solid $green-400;
                }
            }
            &:invalid {
                border: rem(1px) solid $red;
                background-image: none;
                &:focus {
                    border: rem(1px) solid $red;
                    box-shadow: none;
                }
                &:hover {
                    border: rem(1px) solid $red;
                }
            }
        }
        .form-check-input {
            &:valid {
                ~ .form-check-label {
                    color: $green-400;
                }
            }
            &:invalid {
                ~ .form-check-label {
                    &:before {
                        border-color: $red;
                    }
                }
            }
        }
    }


    .form-group {
        margin-bottom: rem(20px);
        @include media-breakpoint-up(lg) { 
            margin-bottom: rem(32px);
        }
        label {
            font-size: rem(16px);
            color: $green-400;
            .required {
                position: relative;
                color: $red;
                left: rem(2px);
            }
        }
        input, textarea {
            border: rem(1px) solid $green-300;
            border-radius: rem(8px);
            background: transparent;
            padding: 16px 16px;
            transition: all .2s cubic-bezier(.4,0,.2,1);
            &:hover, &:focus {
                background: transparent;
                border-color: $green-400;
                outline: none;
                box-shadow: none;
            }
        }

        .invalid-feedback {
            line-height: 1.4;
            margin-top: rem(12px);
        }
    }

    .fieldset-check {
        margin-bottom: rem(32px);
    }
    .form-check-wrapper {
        display: flex;
    }
    .form-check {
        padding: 0;
        input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            &:checked {
                ~ label {
                    &:after {
                        opacity: 1;
                    }
                }  
            }
        }
        label {
            position: relative;
            font-size: rem(16px);
            color: $green-400;
            margin-right: rem(24px);
            padding-left: rem(28px);
            line-height: 1.6;
            &:hover {
                cursor: pointer;
                &:before {
                    border-color: $green-400;
                }
            }
            &:before {
                content: "";
                position: absolute;
                top: rem(2px);
                left: 0;
                display: block;
                width: rem(20px);
                height: rem(20px);
                pointer-events: none;
                background-color: transparent;
                border: rem(1px) solid $green-300;
                border-radius: rem(32px);
                transition: all .2s cubic-bezier(.4,0,.2,1);
            }
            &:after {
                content: "";
                position: absolute;
                opacity: 0;
                top: rem(6px);
                left: rem(4px);
                display: block;
                width: rem(12px);
                height: rem(12px);
                pointer-events: none;
                background-color: $green-400;
                border-radius: rem(32px);
                transition: all .2s cubic-bezier(.4,0,.2,1);
            }
        }
    }
}