// Fonts
$font-body: 'Montserrat', sans-serif;
$font-title: 'Quattrocento', serif;


// Colors
$green-100: #D0D8D2;
$green-200: #ACB2B1;
$green-300: #747F7D;
$green-400: #0C2D2A;

$red: #DC3545;

$bg-green: #E7EDE8;
// 5. Include remainder of required parts
@import "~bootstrap/scss/maps";
// 4. Include any default map overrides here
$form-validation-states: map-remove($form-validation-states, "valid");
