[data-in-view='block'] {
    &.is-in-view {
        & > :nth-child(1) {
            transition-delay: .17s;
        } 

        & > :nth-child(2) {
            transition-delay: .22s;
        } 

        & > :nth-child(3) {
            transition-delay: .27s;
        } 

        & > :nth-child(4) {
            transition-delay: .32s;
        } 

        & > :nth-child(5) {
            transition-delay: .37s;
        } 

        & > :nth-child(6) {
            transition-delay: .42s;
        } 

        & > :nth-child(7) {
            transition-delay: .47s;
        } 

        & > :nth-child(8) {
            transition-delay: .52s;
        } 

        & > :nth-child(9) {
            transition-delay: .57s;
        } 

        > * {
            opacity: 1;
            transform: translateY(0);
            transition: transform 1.4s cubic-bezier(.36,.33,0,1), opacity 1.4s cubic-bezier(.36,.33,0,1);
        }
    }

    > * {
        opacity: 0;
        transform: translateY(40px);
    }
}

[data-in-view='img'] {
    &.is-in-view  {
        > * {
            opacity: 1;
            transform: translateY(0);
            transition: transform 1.4s cubic-bezier(.36,.33,0,1), opacity 1.4s cubic-bezier(.36,.33,0,1);
            transition-delay: .17s;
        }
    }

    > * {
        opacity: 0;
        transform: translateY(40px);
    }
}