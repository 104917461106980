html, body {
    font-size: rem(16px);
    line-height: 1.8;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: $font-body;
    color: $green-300;
}

strong {
    font-weight: bold;
}

section {
    &.section {
        & + .section {
            padding-top: rem(80px);
            @include media-breakpoint-up(lg) { 
                padding-top: rem(120px);
            }
        }

        & + .section__bg {
            margin-top: rem(80px);
            @include media-breakpoint-up(lg) { 
                margin-top: rem(120px);
            }
        }

        &__bg {
            background-color: $bg-green;
        }
        &__jumbotron {
            margin-bottom: rem(40px);
            padding-top: rem(100px); 
            @include media-breakpoint-up(lg) { 
                margin-bottom: rem(24px);
                padding-top: rem(180px); 
            }

            .content {
                padding: rem(10px 0 50px);
                @include media-breakpoint-up(lg) { 
                    padding: rem(50px 0 70px 0);
                }
                .svg {
                    svg {
                        width: rem(70px);
                        position: relative;
                        margin-bottom: rem(32px);
                        @include media-breakpoint-up(md) { 
                            left: rem(-32px);
                            width: rem(105px);
                        }
                    }
                }
            }

            .img {
                img {
                    top: rem(50px);
                    position: relative;
                    margin-top: rem(-50px);
                }
            }
        }
        &:last-child {
            padding-bottom: rem(120px);
        }

        img {
            border-radius: rem(16px);
        }

        &__informations {
            .img {
                img {
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }
                &-description {
                    display: flex;
                    padding: 20px 24px;
                    border-bottom-left-radius: rem(16px);
                    border-bottom-right-radius: rem(16px);
                    background-color: $bg-green;
                    @include media-breakpoint-up(lg) { 
                        padding: 24px 40px;
                    }
                    .subtitle {
                        margin-right: rem(32px);
                        @include media-breakpoint-up(lg) { 
                            margin-right: rem(64px);
                        }
                    }
                    p {
                        font-family: $font-title;
                        font-size: rem(20px);
                        color: $green-400;
                        margin: 0;
                        line-height: 1.3;
                    }
                }
            }

            .program {
                padding-top: rem(24px);
                padding-bottom: rem(4px);
                padding-left: rem(56px);
                list-style: none;
                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                    padding-top: rem(58px);
                    padding-bottom: 0;
                }
                &-item {
                    margin-bottom: rem(36px);
                    h3 {
                        position: relative;
                        svg {
                            position: absolute;
                            top: rem(-10px);
                            left: rem(-56px); 
                        }
                    }
                }
            }
        }

        &__center {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 150px 0;
        }

        &__404 {
            h1 {
                margin-bottom: 0;
            }

            p {
                margin-bottom: rem(32px);
            }
        }

        &__knock-knock {
            padding: 100px 0;
            @include media-breakpoint-up(lg) { 
                padding: 150px 0;
            }
            .row {
                &__content {
                    margin-bottom: rem(24px);
                    text-align: center;
                }

                form {
                    .form-group {
                        &.has-error {
                            input {
                                border: rem(1px) solid $red;
                                background-image: none;
                                &:focus {
                                    border: rem(1px) solid $red;
                                    box-shadow: none;
                                }
                                &:hover {
                                    border: rem(1px) solid $red;
                                }
                            }

                            .error-feedback {
                                width: 100%;
                                margin-top: rem(4px);
                                font-size: rem(14px);
                                color: $red;
                            }
                        }
                    }

                    button {
                        width: 100%;
                    }
                }
            }
        }

        &__confirmation {
            .svg {
                svg {
                    width: rem(70px);
                    position: relative;
                    margin-bottom: rem(24px);
                    @include media-breakpoint-up(md) { 
                        left: rem(-32px);
                        width: rem(105px);
                    }
                }
            }

            .btn {
                margin-top: rem(24px);
            }
        }

    }
}

footer {
    
    padding-top: rem(40px);
    padding-bottom: rem(32px);
    @include media-breakpoint-down(md) { 
        text-align: center;
    }
    @include media-breakpoint-up(lg) { 
        padding-top: rem(40px);
        padding-bottom: rem(40px);
    }
    ul {
        &.phones {
            list-style: none;
            padding: 0;
            margin: rem(16px 0 40px 0);
            @include media-breakpoint-up(md) { 
                margin: 0;
                margin-bottom: rem(16px);
            }
            li {
                color: $green-400;
                a {
                    color: $green-400;
                    text-decoration: underline;
                    transition: all .2s cubic-bezier(.4,0,.2,1);
                    &:hover {
                        color: $green-300;
                    }
                }
            }
        }
    }

    .address {
        svg {
            margin-bottom: rem(32px);
        }
        p {
            line-height: 1.6;
        }
        .text-uppercase {
            color: $green-400;
            line-height: 1.4;
            margin-bottom: rem(6px);
            @include media-breakpoint-up(lg) { 
                margin-bottom: rem(10px);
            }
        }
    }
}