h1, h2, h3, h4, h5 {
    font-family: $font-title;
    color: $green-400;
    line-height: 1.1;
}

h1 {
    font-size: rem(48px);
    @include media-breakpoint-up(lg) { 
        font-size: rem(72px);
        margin-bottom: rem(20px);
    }
    &.big-h1 {
        font-size: rem(110px);
        @include media-breakpoint-up(lg) { 
            font-size: rem(148px);
            margin-bottom: rem(28px);
        }
    }
}

h2 {
    font-size: rem(32px);
    @include media-breakpoint-up(lg) { 
        font-size: rem(48px);
    }
}

h3 {
    font-size: rem(24px);
    margin-bottom: rem(4px);
    @include media-breakpoint-up(lg) { 
        font-size: rem(32px);
    }
}

h4 {
    font-size: rem(18px);
    @include media-breakpoint-up(lg) { 
        font-size: rem(24px);
    }
}

.subtitle {
    font-size: rem(14px);
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: rem(8px);
    @include media-breakpoint-up(lg) { 
        margin-bottom: rem(20px);
    }
}