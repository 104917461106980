.btn {
    transition: all .2s cubic-bezier(.4,0,.2,1);
    border: none;
    &-primary {
        font-size: rem(16px);
        color: $green-100;
        padding: 14px 24px;
        background-color: $green-400;
        border-radius: rem(32px);
        &:hover, &:focus, &:focus-visible {
            color: $white;
            background-color: darken($color: $green-400, $amount: 5);
            box-shadow: none;
        }
        &:active {
            &:focus {
                color: $white;
                background-color: darken($color: $green-400, $amount: 5);
            }
        }

        &:first-child {
            &:active {
                color: $white;
                background-color: darken($color: $green-400, $amount: 5);
            }
        }
    } 
} 
