header {
    position: fixed;
    background-color: $bg-green;
    width: 100%;
    height: rem(80px);
    transition: all .2s cubic-bezier(.4,0,.2,1);
    z-index: 12;
    @include media-breakpoint-up(lg) { 
        padding-top: rem(14px);
        height: rem(140px);
    }
    &.scrolled {
        padding-top: rem(0);
        box-shadow: 0 0 transparent,0 0 transparent, 0 0 transparent,0 0 transparent, 0 1px 2px 0 rgba(0,0,0,0.05);
        height: rem(80px);
        .navbar {
            height: rem(80px);
        }
    }
    .navbar {
        padding: 0;
        height: rem(80px);
        transition: all .2s cubic-bezier(.4,0,.2,1);
        @include media-breakpoint-up(lg) { 
            height: rem(140px); 
        }
        .navbar-brand {

        }
        ul {
            display: flex;
            align-items: center;
            margin: 0;
            li {
                list-style: none;
                a:not(.btn) {
                    color: $green-300;
                    text-decoration: none;
                    transition: all .2s cubic-bezier(.4,0,.2,1);
                    &:hover {
                        color: darken($color: $green-400, $amount: 5);
                    }
                }
                & + li {
                    margin-left: rem(28px);
                    @include media-breakpoint-up(md) {
                        margin-left: rem(32px);
                    }
                }
            }
        }
    }
}